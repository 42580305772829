import React from "react";
import {
  Box,
  Heading,
  Text,
  Code,
  UnorderedList,
  ListItem,
  Link,
  Divider,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Flex,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

const ExternalLink = ({ children, ...props }) => (
  <Link color="teal.500" isExternal {...props}>
    {children} <ExternalLinkIcon mx="2px" />
  </Link>
);

const ArxivXplorerPage = () => {
  const colorMode = useColorModeValue("gray.200", "gray.700");
  const textColorMode = useColorModeValue("gray.600", "gray.300");

  return (
    <Layout showTitle={true} showFooter={false}>
      <Box maxW="800px" mx="auto" px={4} pb={24}>
        <Heading as="h1" size="2xl" mb={4}>
          arXiv Xplorer GPT
        </Heading>
        <Text fontSize="md" mb={4} fontStyle="oblique">
          Discover, read, reference, and search arXiv right from your chat.
        </Text>
        <Text fontSize="lg" mb={4}>
          ArXiv Xplorer enables semantic search over the entire arXiv corpus,
          and within the content of each paper. It directly uses the Latex
          source, so the extracted text and formulae are much higher quality,
          falling back to PDF when not available. Try it out for free now!
        </Text>
        <Text fontSize={"xl"} mb={12}>
          <ExternalLink href="https://chat.openai.com/g/g-WeT9tE7SR-arxiv-xplorer">
            Open the arXiv Xplorer GPT
          </ExternalLink>
        </Text>

        <Heading as="h2" size="lg" mt={8}>
          Available Functions
        </Heading>
        <Text mb={4} fontSize="lg">
          ArXiv Xplorer equips ChatGPT with the following functions to navigate
          through arXiv papers:
        </Text>
        <Text fontSize="lg" mb={{ base: 16, md: 24 }}>
          <UnorderedList spacing={2}>
            <ListItem>
              <strong>search</strong>: Search for papers using three distinct
              methods:
              <UnorderedList ml={4}>
                <ListItem>
                  <strong>semantic</strong>: Obtain contextually relevant
                  results with{" "}
                  <ExternalLink href="https://en.wikipedia.org/wiki/Semantic_search">
                    semantic search
                  </ExternalLink>
                  .
                </ListItem>
                <ListItem>
                  <strong>keyword</strong>: Pinpoint exact terms, like a paper
                  title, author or another keyword.
                </ListItem>
                <ListItem>
                  <strong>similarity</strong>: Discover more papers like a
                  specified paper.
                </ListItem>
              </UnorderedList>
              <Code>search("Quantum computing basics", method="semantic")</Code>
            </ListItem>
            <Divider />
            <ListItem>
              <strong>read_paper_metadata</strong>: Get important details of a
              paper such as title, authors, publication date, full abstract, and
              table of contents.
              <br />
              <Code>read_paper_metadata("1706.03762")</Code>
            </ListItem>
            <Divider />
            <ListItem>
              <strong>chunk_search</strong>: Perform a semantic search within
              specific sections of a paper. Ideal for in-depth exploration of
              specific topics.
              <br />
              <Code>
                chunk_search(paper_id="quant-ph/9802065", query="intro to Shor's
                algorithm")
              </Code>
            </ListItem>
            <Divider />
            <ListItem>
              <strong>read_section</strong>: Extract specific sections or
              subsections from a paper's content. Also returns any figures
              inside the section
              <br />
              <Code>read_section(paper_id="1706.03762", section_id=[3,1])</Code>
            </ListItem>
            <Divider />
            <ListItem>
              <strong>read_citation</strong>: Delve into specific citations
              within a paper. You can then lookup those papers!
              <br />
              <Code>
                read_citation(paper_id="1706.03762", citation="he2016deep")
              </Code>
            </ListItem>
            <ListItem>
              <strong>get_figure</strong>: Get a figure URL, caption, and
              section title, by the figure's latex reference.
              <br />
              <Code>
                get_figure(paper_id="1706.03762",
                figure_id="fig:multi-head-att")
              </Code>
            </ListItem>
          </UnorderedList>
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          Examples
        </Heading>
        <Box bg={colorMode} p={4} rounded="md">
          <Heading as="h3" size="md">
            <ExternalLink href="https://chat.openai.com/share/b1f687c9-6079-4113-910a-9174f4d676b7">
              Example 1: Exploring Quantum Computing
            </ExternalLink>
          </Heading>

          <Text color={textColorMode} mb={4}>
            Dive into the basics of quantum computing, with <Code>search</Code>,{" "}
            <Code>chunk_search</Code> and <Code>read_section</Code>.
          </Text>
          <StaticImage src="../images/quantum-example.png" alt="quantum computing example" />
        </Box>

        <Box bg={colorMode} p={4} rounded="md" mt={8} mb={4}>
          <Heading as="h3" size="md">
            <ExternalLink href="https://chat.openai.com/share/421178ed-c028-45ff-a009-9e199baf489c">
              Example 2: Understanding the Transformer Architecture
            </ExternalLink>
          </Heading>
          <Text color={textColorMode} mb={4}>
            Delve into the transformer architecture, using{" "}
            <Code>read_paper_metadata</Code>, <Code>read_section</Code> and{" "}
            <Code>read_citation</Code>.
          </Text>
          <StaticImage src="../images/transformer-example.png" alt="transformer paper example" />
        </Box>

        <Box bg={colorMode} p={4} rounded="md" mt={8} mb={{ base: 16, md: 24 }}>
          <Heading as="h3" size="md">
            <ExternalLink href="https://chat.openai.com/share/173d5584-39b5-4371-a60d-8c98aad8eb56">
              Example 3: Comparing the number of parameters in the Llama 2 Model
            </ExternalLink>
          </Heading>
          <Text color={textColorMode} mb={4}>
            Find intricate model details and figures using{" "}
            <Code>read_section</Code> and <Code>get_figure</Code>.
          </Text>
          <StaticImage src="../images/llama-example.png" alt="llama paper example" />
        </Box>

        <Heading as="h2" size="lg" mt={8}>
          Tips & Tricks
        </Heading>
        <Text fontSize="lg" mb={4}>
          To make the most of the arXiv Xplorer plugin, consider the following
          best practices:
        </Text>
        <Text fontSize="lg" mb={8}>
          <UnorderedList spacing={2}>
            <ListItem>
              Remember that ChatGPT is sensitive to prompting! Be clear with
              what you expect from the plugin and ChatGPT will follow your lead.
            </ListItem>
            <ListItem>
              Add your own custom instructions, like "Be concise" or "Add
              section and paper references".
            </ListItem>
            <ListItem>
              For optimal results with semantic search, be as descriptive as
              possible.
            </ListItem>
            <ListItem>
              If you already know the arXiv ID of a paper, use it directly for
              quicker access.
            </ListItem>
            <ListItem>
              The first time a paper is read may take a few seconds to load.
              Subsequent queries are much faster!
            </ListItem>
          </UnorderedList>
        </Text>

        <Flex justify="center" align="center">
          <Link
            color="teal.500"
            isExternal
            href="https://chat.openai.com/g/g-WeT9tE7SR-arxiv-xplorer"
          >
            <Button size="lg">
              Open the arXiv Xplorer GPT{"  "}
              <ExternalLinkIcon mx="2px" />
            </Button>
          </Link>
        </Flex>

        <Accordion allowToggle mb={{ base: 16, md: 24 }} mt={12}>
          <AccordionItem>
            <AccordionButton>
              <Heading as="h2" size="md">
                Install as Plugin (Deprecated)
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text mb={{ base: 16, md: 24 }} fontSize="lg">
                <UnorderedList spacing={2}>
                  <ListItem>
                    <ExternalLink href="https://chat.openai.com/?model=gpt-4-plugins">
                      Open ChatGPT-4 with plugins
                    </ExternalLink>
                  </ListItem>
                  <ListItem>
                    Make sure plugins are enabled under Settings {">"} Beta
                    features
                    {">"} Plugins.
                  </ListItem>
                  <StaticImage
                    src="../images/enable-plugins.png"
                    alt="enable chatgpt plugins (deprecated)"
                  />
                  <ListItem>
                    Navigate to the Plugins dropdown at the top and access the
                    plugin store.
                  </ListItem>
                  <ListItem>
                    Search for "arXiv Xplorer" and click "Install".
                  </ListItem>
                  <StaticImage
                    src="../images/arxiv-xplorer-plugin.png"
                    alt="add arxiv xplorer plugin"
                  />
                </UnorderedList>
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Layout>
  );
};

export default ArxivXplorerPage;
